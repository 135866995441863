import { Avatar, Text, Paper } from '@mantine/core';

export function BrandCard({ brand }) {
    return (
        <Paper radius="md" withBorder p="lg" bg="var(--mantine-color-body)">
            <Avatar
                src={brand.logo}
                size={120}
                radius={120}
                mx="auto"
            />
            <Text ta="center" fz="lg" fw={500} mt="md">
                {brand.name}
            </Text>
        </Paper>
    );
}