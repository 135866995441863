import { collection, doc, getDoc, getDocFromServer, getDocs, limit, orderBy, query, startAfter, where } from 'firebase/firestore';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { db } from '../../firebase-config';
import { Button, Container, Loader, Text } from '@mantine/core';
import { Helmet } from 'react-helmet';
import { UsersTable } from '../../components/usersTable/UsersTable';
import styles from './users.module.css';
import { AppContext } from '../../App';

export const Users = () => {
    const [latestUsers, setLatestUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    var lastVisible = useRef(null);
    const [currentPage, setCurrentPage] = useState(1);
    const fetchLimit = 10;

    const { globalState } = useContext(AppContext);

    useEffect(() => {
        fetchLatestData(fetchLimit, lastVisible);
    }, []);

    // fetch latest 10 stamps and rewards with unique userId
    const fetchLatestData = async (fetchLimit, lastVisible) => {
        setLoading(true);
        try {
            var querySnapshot;
            if (!lastVisible.current) {
                querySnapshot = await getDocs(query(collection(db, 'users'), orderBy('name'), limit(fetchLimit)));
            } else {
                querySnapshot = await getDocs(query(collection(db, 'users'), orderBy('name'), startAfter(lastVisible.current), limit(fetchLimit)));
            }
            const users = querySnapshot.docs.map(doc => doc.data());
            lastVisible.current = querySnapshot.docs[querySnapshot.docs.length - 1];
            // document's name is userId
            const userIds = querySnapshot.docs.map(doc => doc.id);

            // var querySnapshot;
            // if (!lastVisible.current) {
            const stamps = await Promise.all(userIds.map(async userId => {
                var stampsQuerySnapshot = await getDocs(query(collection(db, 'stamps'), where('userId', '==', userId)));
                const stamps = stampsQuerySnapshot.docs.map(doc => doc.data());
                const brandIds = Array.from(new Set(stamps.map(stamp => stamp.brandId)));

                const brands = await Promise.all(brandIds.map(async brandId => {
                    const brandDoc = await getDocFromServer(doc(db, 'brands', brandId));
                    let brand = brandDoc.data();
                    brand.id = brandId;
                    return brand;
                }));

                // now merge stamps data where userId and stampedDate are repeated
                // const stampsData = stamps.reduce((acc, stamp) => {
                //     const existingStamp = acc.find(s => s.userId === stamp.userId && s.stampedDate.seconds === stamp.stampedDate.seconds && s.brandId === stamp.brandId);
                //     const brand = brands.find(brand => brand.id === stamp.brandId);
                //     stamp.brand = brand;
                //     if (existingStamp) {
                //         existingStamp.count++;
                //     } else {
                //         acc.push({ ...stamp, count: 1 });
                //     }
                //     return acc;
                // }, []);
                const stampsData = stamps.map(stamp => {
                    const brand = brands.find(brand => brand.id === stamp.brandId);
                    stamp.brand = brand;
                    return stamp;
                });
                return stampsData;
            }));

            const rewards = await Promise.all(userIds.map(async userId => {
                var rewardsQuerySnapshot = await getDocs(query(collection(db, 'rewards'), where('userId', '==', userId)));
                const rewards = rewardsQuerySnapshot.docs.map(doc => doc.data());
                return rewards;
            }));

            users.forEach(async (user, index) => {
                let stampCards = [];

                user.id = userIds[index];
                user.stamps = stamps[index];
                user.stampsCount = user.stamps.length;
                user.brands = Array.from(new Set(user.stamps.map(stamp => stamp.brand)));
                user.brandsCount = user.brands.length;
                user.rewards = rewards[index];
                user.rewardsCount = user.rewards.length;

                const stampCardIds = user.stamps.reduce((acc, stamp) => {
                    if (stamp.claimed === false) {
                        const stampCardId = stamp.stampCardId;
                        acc[stampCardId] = (acc[stampCardId] || 0) + 1;
                    }
                    return acc;
                }, {});

                Object.keys(stampCardIds).map(async (stampCardId) => {
                    // fetch stampCard data
                    setLoading(true);
                    try {
                        const stampCarSnapshot = await getDoc(doc(db, 'stampCards', stampCardId));
                        const stampCard = stampCarSnapshot.data();
                        const brand = user.brands.find((brand) => brand.id === stampCard.brandId);

                        // stampCards with count > totalStamps needs to be split
                        if (stampCardIds[stampCardId] > stampCard.totalStamps) {
                            const count = stampCardIds[stampCardId];
                            const totalStamps = stampCard.totalStamps;
                            const remainingCount = count - totalStamps;
                            const newStampCard = { ...stampCard, brand, stampCardId, count: totalStamps };
                            stampCards.push(newStampCard);
                            stampCards.push({ ...stampCard, brand, stampCardId, count: remainingCount });
                        } else {
                            stampCards.push({ ...stampCard, brand, stampCardId, count: stampCardIds[stampCardId] });
                        }
                    } catch (error) {
                        console.error('Error getting document:', error);
                        setError('Error getting document');
                    } finally {
                        setLoading(false);
                    }
                });

                user.stampCards = stampCards;
            });

            setLatestUsers([...latestUsers, ...users]);
            setLoading(false);
        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>Lollet Admin - Users</title>
            </Helmet>
            <Container size="xxl" className={styles.container}>
                {error && <Text color="red" align="center">{error}</Text>}
                {loading ? (
                    <Loader />
                ) : (
                    <Container size="xxl" className={styles.container}>
                        <UsersTable data={latestUsers} filter={globalState.currentBrand} loading={loading} error={error} />
                        <Container align='center' justify='center'>
                            <Button onClick={() => { setCurrentPage(currentPage + 1); fetchLatestData(fetchLimit, lastVisible) }}>Show More</Button>
                        </Container>
                    </Container>
                )}
            </Container>
        </>
    )
}