import { Outlet } from "react-router-dom";
import { NavBar } from "../navBar/NavBar";
import { onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { auth } from "../../firebase-config";

export const AppLayout = () => {
    const [LoggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (!user) {
                setLoggedIn(false);
            }
            else {
                setLoggedIn(true);
            }
        });

        // Clean up the subscription
        return () => unsubscribe();
    }, []);

    return (
        <div style={{
            padding: '0px 0px 0px 0px',
            height: '100vh',
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                height: '100vh',
                gap: '0px',
            }}>
                {LoggedIn &&
                    <NavBar />}
                <div style={{
                    flex: '1',
                    overflowY: 'scroll',
                }}>
                    <Outlet />
                </div>
            </div>
        </div>
    );
};