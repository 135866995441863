import React, { useEffect, useState } from 'react';
import { Modal, Text } from '@mantine/core';
import { Button } from '@mantine/core';

import styles from './confirmBox.module.css';
import { IconX } from '@tabler/icons-react';

export const ConfirmBox = ({
  title = 'Confirmation',
  message = 'Are you sure?',
  confirmButtonText = 'Confirm',
  size = 'sm',
  onConfirm,
  onCancel = () => { },
  disabled = false,
  forceOpen = false,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (forceOpen && !disabled) {
      setIsOpen(true);
    }
  }, [forceOpen, disabled]);

  const handleClose = () => {
    onCancel();
    setIsOpen(false);
  };

  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };

  const handleOpen = () => {
    if (!disabled) {
      setIsOpen(true);
    }
  };

  return (
    <>
      {React.cloneElement(children, { onClick: handleOpen })}
      <Modal
        opened={isOpen}
        onClose={handleClose}
        centered
        withCloseButton={false}
        size={size}
        padding={0}
      >
        <div className={styles.header}>
          {/* <Text variant="h6" component="div" sx={{ flexGrow: 1 }}> */}
          <Text fz="xl" fw={500}>
            {title}
          </Text>
          {/* </Text> */}
          <button onClick={handleClose} className={styles.cross_button} ><IconX fontSize='medium' /></button>
        </div>

        <div className={styles.message}>
          <Text fz="md" fw={400}>
            {message}
          </Text>
        </div>
        
        <div className={styles.buttons}>
          <Button
            className={styles.cancel_btn}
            type="button"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className={confirmButtonText.toLowerCase().includes('delete') ? styles.delete_btn : styles.btn}
            type="button"
            onClick={handleConfirm}
            color={confirmButtonText.toLowerCase().includes('delete') ? 'red' : null}
          >
            {confirmButtonText}
          </Button>
        </div>
      </Modal>
    </>
  );
};
