import React, { useEffect, useState } from 'react';
import { collection, doc, getCountFromServer, getDocFromServer, query, where } from 'firebase/firestore';
import { db } from '../../firebase-config';
import { Container, Loader, Text, Modal, Group, Space } from '@mantine/core';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { BrandCard, StampCard, UserCard } from '../../components';

export const User = ({ userData, open, setOpen, hoverCard, hoverId }) => {
    const params = useParams();
    const [user, setUser] = useState(userData);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [modalOpen, setModalOpen] = useState(false);

    if (hoverCard) {
        params.id = hoverId;
    }

    const closeModal = () => {
        setModalOpen(false);
    };

    useEffect(() => {
        if (open) {
            setModalOpen(true);
            setUser(userData);
        }
    }, [open, userData]);

    useEffect(() => {
        const fetchUser = async () => {
            setLoading(true);
            try {
                const docRef = doc(db, 'users', params.id);
                const docSnap = await getDocFromServer(docRef);

                if (docSnap.exists()) {
                    let user = docSnap.data();
                    const fetchCollectionCount = async (coll, fieldName) => {
                        const q = query(collection(db, coll), where(fieldName, '==', params.id));
                        const querySnapshot = await getCountFromServer(q);
                        return querySnapshot.data().count;
                    };

                    const [stampsCount, rewardsCount] = await Promise.all([
                        fetchCollectionCount('stamps', 'userId'),
                        fetchCollectionCount('rewards', 'userId')
                    ]);

                    user.stampsCount = stampsCount;
                    user.rewardsCount = rewardsCount;
                    setUser(user);
                } else {
                    setError('User not found');
                }
            } catch (error) {
                console.error('Error getting document:', error);
                setError('Error getting document');
            } finally {
                setLoading(false);
            }
        };


        if (params.id && !user) {
            fetchUser();
        }
    }, [params.id]);

    console.log(user);

    function mainBody() {
        return (
            <>
                {/* User Details Section */}
                <UserCard user={user} />

                {/* brands section */}
                {user.brands?.length > 0 &&
                    <Container mt="lg">
                        <Text align="center" fz={20} fw={700}>BRANDS VISITED</Text>
                        <Group justify='center' m={20}>
                            {user.brands?.map((brand) => {
                                console.log(brand);
                                return <BrandCard key={brand.id} brand={brand} />;
                            })}
                        </Group>

                        <Space py="lg" />
                    </Container>
                }

                {/* Stamp Cards Section */}
                {user.stampCards?.length > 0 &&
                    <Container mt="lg">
                        <Text align="center" fz={20} fw={700}>STAMP CARDS</Text>
                        <Group justify='center' m={20}>
                            {user.stampCards.map((stampCard, index) => (
                                <StampCard key={stampCard.stampCardId + index} stampCard={stampCard} />
                            ))}
                        </Group>

                        <Space py="lg" />
                    </Container>
                }
            </>
        );
    }

    return (
        <Container>
            <Helmet>
                <title>User - {user?.name || 'Unknown'}</title>
            </Helmet>
            {loading && <Loader />}
            {error && <Text align="center" color="red">{error}</Text>}
            {user && (
                params.id ? (
                    mainBody()
                ) : (
                    <Modal
                        fullScreen
                        opened={modalOpen || open}
                        onClose={
                            () => {
                                closeModal();
                                setOpen(false);
                            }
                        }
                    >
                        {mainBody()}
                    </Modal>
                )
            )}
        </Container>
    );
};