import { Avatar, Badge, Table, Group, Text, ActionIcon, rem, Loader, Tooltip, Container, HoverCard } from '@mantine/core';
import { IconPencil, IconTrash } from '@tabler/icons-react';
import { User } from '../../pages';

export function StampsTable({ data, loading, error }) {
    if (loading) {
        return <Loader />;
    }

    // refactor data 
    data = data.map((item) => {
        return {
            id: item.userId,
            name: item.user?.name || 'Unknown',
            phone: item.user?.phone || 'Unknown',
            image: item.user?.image,
            date: item.stampedDate.seconds,
            stampCount: item.count,
            brand: item.brand?.name || 'Unknown',
            actions: false,
        };
    });

    const rows = data.map((item, index) => (
        <Table.Tr key={index}>
            <Table.Td>
                <Group gap="sm">
                    <HoverCard shadow="md" openDelay={600} radius={20}>
                        <HoverCard.Target>
                            <Group gap="sm" style={{ cursor: 'pointer' }}>
                                <Avatar size={30} src={item.image} radius={30} />
                                <Text fz="sm" fw={500}>
                                    {item.name}
                                </Text>
                            </Group>
                        </HoverCard.Target>
                        <HoverCard.Dropdown>
                            <User hoverCard={true} hoverId={item.id} />
                        </HoverCard.Dropdown>
                    </HoverCard>
                </Group>
            </Table.Td>

            <Table.Td>
                <Text fz="sm">{item.phone}</Text>
            </Table.Td>

            <Table.Td>
                <Text fz="sm">{new Date(item.date * 1000).toLocaleString()}</Text>
            </Table.Td>

            <Table.Td>
                <Text fz="sm">{item.brand}</Text>
            </Table.Td>

            <Table.Td>
                <Badge variant="dark" size='lg'>
                    {item.stampCount}
                </Badge>
            </Table.Td>

            {item.actions &&
                <Table.Td>
                    <Group gap={0} justify="flex-end">
                        <ActionIcon variant="subtle" color="gray">
                            <IconPencil style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                        </ActionIcon>
                        <ActionIcon variant="subtle" color="red">
                            <IconTrash style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                        </ActionIcon>
                    </Group>
                </Table.Td>
            }
        </Table.Tr>
    ));

    return (
        <>
            <Table.ScrollContainer minWidth={800}>
                <Table verticalSpacing="sm">
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th>User</Table.Th>
                            <Table.Th>Phone</Table.Th>
                            <Table.Th>Date</Table.Th>
                            <Table.Th>Brand</Table.Th>
                            <Table.Th>Stamps</Table.Th>
                            <Table.Th>Actions</Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>{rows}</Table.Tbody>
                </Table>
            </Table.ScrollContainer>
        </>
    );
}