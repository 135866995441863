import { Avatar, Table, Group, Text, ActionIcon, rem, Loader } from '@mantine/core';
import { IconPencil, IconTrash } from '@tabler/icons-react';
import { User } from '../../pages';
import { useState } from 'react';
import { EditBrand } from '../editBrandModal/EditBrandModal';
import { deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase-config';
import { ConfirmBox } from '../confirmBox/ConfirmBox';

export function BrandsTable({ data, filter, loading, error, fetchLatestData }) {
    const [selectedBrand, setSelectedBrand] = useState({});
    const [openBrandDetails, setOpenBrandDetails] = useState(false);

    if (loading) {
        return <Loader />;
    }

    if (filter && filter !== 'All') {
        data = data.filter((item) => (item.brands).find((brand) => brand.id === filter));
    }

    const deleteBrand = async (brand) => {
        let docRef = doc(db, 'brands', brand.id);
        await deleteDoc(docRef);
        fetchLatestData();
    }

    // refactor data 
    data = data.map((item) => {
        return {
            ...item,
            actions: true,
        };
    });

    console.log(data);

    const rows = data.map((item, index) => (
        <Table.Tr key={index}>
            <Table.Td>
                <Text fz="sm">{index + 1}</Text>
            </Table.Td>
            <Table.Td>
                <Group gap="sm">
                    <Avatar size={30} src={item.logo} radius={30} />
                    <Text fz="sm" fw={500}>
                        {item.name}
                    </Text>
                </Group>
            </Table.Td>

            {item.actions &&
                <Table.Td>
                    <Group gap={0} justify="flex-start">
                        <ActionIcon variant="subtle" color="gray" onClick={
                            () => {
                                setSelectedBrand(item);
                                setOpenBrandDetails(true);
                            }
                        }>
                            <IconPencil style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                        </ActionIcon>
                        <ConfirmBox onConfirm={() => deleteBrand(item)} >
                            <ActionIcon variant="subtle" color="red" >
                                <IconTrash style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                            </ActionIcon>
                        </ConfirmBox>
                    </Group>
                </Table.Td>
            }
        </Table.Tr>
    ));

    return (
        <>
            <Table.ScrollContainer minWidth={800}>
                <Table verticalSpacing="sm">
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th>SN</Table.Th>
                            <Table.Th>Brand</Table.Th>
                            <Table.Th>Actions</Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>{rows}</Table.Tbody>
                </Table>
            </Table.ScrollContainer>

            <EditBrand brandData={selectedBrand} open={openBrandDetails} setOpen={setOpenBrandDetails} fetchLatestData={fetchLatestData} />
        </>
    );
}