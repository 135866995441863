import { useContext, useEffect, useState } from 'react';
import { Center, Tooltip, UnstyledButton, Stack, rem, Image, Text, Select, Modal } from '@mantine/core';
import { IconBuildingStore, IconGauge, IconLogout, IconQrcode, IconSwitchHorizontal, IconUsers } from '@tabler/icons-react';
import classes from './NavBar.module.css';
import { AppContext } from '../../App';
import { collection, getDocsFromServer, query } from 'firebase/firestore';
import { db } from '../../firebase-config';
import { useDisclosure } from '@mantine/hooks';

function NavbarLink({ icon: Icon, label, active, onClick }) {
  return (
    <Tooltip label={label} position="right" transitionProps={{ duration: 0 }}>
      <UnstyledButton onClick={onClick} className={classes.link} data-active={active || undefined}>
        <Icon style={{ width: rem(20), height: rem(20) }} stroke={1.5} />
      </UnstyledButton>
    </Tooltip>
  );
}

const navData = [
  { icon: IconGauge, label: 'Dashboard', to: '/admin/dashboard' },
  { icon: IconBuildingStore, label: 'Brands', to: '/admin/brands' },
  { icon: IconUsers, label: 'Users', to: '/admin/users' },
];

export function NavBar() {
  const [active, setActive] = useState(0);
  const { globalState, setGlobalState } = useContext(AppContext);
  const [opened, { open, close }] = useDisclosure(false);
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    const pathname = window.location.pathname;
    console.log(pathname);
    setActive(navData.findIndex((link) => link.to === pathname));
  }, []);

  useEffect(() => {
    const fetchBrands = async () => {
      const q = query(collection(db, 'brands'));
      const querySnapshot = await getDocsFromServer(q);
      const brands = querySnapshot.docs.map((doc) => doc.data());
      const brandIds = querySnapshot.docs.map((doc) => doc.id);
      brands.forEach((brand, index) => {
        brand.id = brandIds[index];
      });
      console.log(brands);
      setBrands(brands);
      setGlobalState((state) => ({ ...state, brands }));
    };

    if (globalState.brands.length === 0) fetchBrands();
  }, []);

  const links = navData.map((link, index) => (
    <NavbarLink
      {...link}
      key={link.label}
      active={index === active}
      onClick={() => {
        setActive(index);
        window.location.href = link.to;
      }}
    />
  ));

  return (
    <nav className={classes.navbar}>
      <Center>
        {(globalState.currentBrand !== 'All' && globalState.brands.length !== 0) ?
          <Image src={(globalState.brands)
            .find((brand) => brand.id === globalState.currentBrand).logo
          } alt="Brand logo" height={40} />
          :
          <Image src="/assets/logo_biz.png" alt="Mantine logo" height={40} />
        }
        {/* <Image src="/assets/logo_biz.png" alt="Mantine logo" height={40} /> */}
      </Center>

      {/* {globalState.currentBrand !== 'All' && globalState.brands.length !== 0 && <Text align="center" fz={10} fw={700}>{(globalState.brands)
        .find((brand) => brand.id === globalState.currentBrand).name
      }</Text>} */}

      <div className={classes.navbarMain}>
        <Stack justify="center" gap={0}>
          {links}
        </Stack>
      </div>

      <Stack justify="center" gap={0}>
        <Modal opened={opened} onClose={close} title="Switch Brand" size="md" padding="md">
          <Select
            data={[...brands.map((brand) => ({ value: brand.id, label: brand.name })), { value: 'All', label: 'All' }]}
            placeholder="Select Brand"
            value={globalState.currentBrand}
            searchable
            defaultValue={{ value: 'All', label: 'All' }}
            onChange={(value) => {
              setGlobalState((state) => ({ ...state, currentBrand: value }));
              localStorage.clear();
              localStorage.setItem('currentBrand', value);
            }}
            allowDeselect={false}
          />
        </Modal>
        <NavbarLink icon={IconQrcode} label="Generate QRs" onClick={() => { window.location.href = '/admin/generateQrs' }} />
        <NavbarLink icon={IconSwitchHorizontal} label="Switch Brand" onClick={open} />
        <NavbarLink icon={IconLogout} label="Logout" onClick={() => { window.location.href = '/admin/logout'; }} />
      </Stack>
    </nav>
  );
}