import { useEffect } from 'react';
import { auth } from '../../firebase-config';
import { onAuthStateChanged } from 'firebase/auth';

export const Logout = () => {
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (!user) {
                window.location.href = '/admin/login';
            }
        });
        logout();

        // Clean up the subscription
        return () => unsubscribe();
    }, []);

    const logout = () => {
        auth.signOut().then(() => {
            window.location.href = '/admin/login';
        }).catch((error) => {
            console.error("Error signing out", error);
            alert('Error signing out');
        });
    };
}