import { collection, getDocs, limit, orderBy, query, startAfter } from 'firebase/firestore';
import React, { useEffect, useRef, useState } from 'react'
import { db } from '../../firebase-config';
import { ActionIcon, Button, Container, Group, Loader, rem, Text } from '@mantine/core';
import { Helmet } from 'react-helmet';
import styles from './brands.module.css';
import { BrandsTable } from '../../components';
import { IconRefresh } from '@tabler/icons-react';
import { EditBrand } from '../../components/editBrandModal/EditBrandModal';

export const Brands = () => {
    const [brands, setBrands] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [openAddBrand, setOpenAddBrand] = useState(false);
    useEffect(() => {
        fetchLatestData();
    }, []);

    // fetch latest 10 stamps and rewards with unique userId
    const fetchLatestData = async () => {
        setLoading(true);
        try {
            var querySnapshot = await getDocs(query(collection(db, 'brands'), orderBy('name')));
            const brands = querySnapshot.docs.map(doc => doc.data());
            const brandIds = querySnapshot.docs.map(doc => doc.id);

            brands.forEach((brand, index) => {
                brand.id = brandIds[index];
            });

            setBrands([...brands]);
            setLoading(false);
        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>Lollet Admin - Brands</title>
            </Helmet>
            <Container size="xxl" className={styles.container}>
                {error && <Text color="red" align="center">{error}</Text>}
                {loading ? (
                    <Loader />
                ) : (
                    <>
                        <Group justify="space-between">
                            <Button onClick={
                                () => {
                                    setOpenAddBrand(true);  
                                }
                            }>Add Brand</Button>
                            <ActionIcon variant="subtle" onClick={fetchLatestData}>
                                <IconRefresh style={{ width: rem(30), height: rem(30) }} stroke={1.5} />
                            </ActionIcon>
                        </Group>
                        <Container size="xxl" className={styles.container}>
                            <BrandsTable data={brands} loading={loading} error={error} fetchLatestData={fetchLatestData} />
                        </Container>

                        <EditBrand brandData={{}} open={openAddBrand} setOpen={setOpenAddBrand} mode={'new'} fetchLatestData={fetchLatestData} />
                    </>
                )}
            </Container>
        </>
    )
}