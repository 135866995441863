import { Button, Container, Loader, Modal, Space, Text, TextInput } from "@mantine/core";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../firebase-config";

export const EditBrand = ({ brandData, open, setOpen, mode, fetchLatestData }) => {
    const [brand, setBrand] = useState(brandData);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [modalOpen, setModalOpen] = useState(false);

    const closeModal = () => {
        setModalOpen(false);
    };

    useEffect(() => {
        if (open) {
            setModalOpen(true);
            setBrand(brandData);
        }
    }, [open, brandData]);

    async function onSave() {
        setLoading(true);
        setError('');

        if (mode === 'new') {
            let docRef = await addDoc(collection(db, 'brands'), {
                name: brand.name,
                logo: brand.logo || '',
            });
            brand.id = docRef.id;
        } else {
            let docRef = doc(db, 'brands', brand.id);
            await updateDoc(docRef, {
                name: brand.name,
                logo: brand.logo,
            });
        }
        fetchLatestData();
        setLoading(false);
        closeModal();
        setOpen(false);
    }

    return (
        <Modal opened={modalOpen || open} onClose={() => {
            closeModal();
            setOpen(false);
        }} title="Edit Brand" size="md" padding="md">
            <div>
                {error && <Text color="red" align="center">{error}</Text>}
                {loading ? (
                    <Loader />
                ) : (
                    <Container size="xl">
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            setLoading(true);
                            setError('');
                            // update brand
                            setLoading(false);
                            closeModal();
                            setOpen(false);
                        }}>

                            {brand.id && <TextInput label="Brand ID" value={brand.id} disabled />}
                            <Space h="md" />
                            <TextInput
                                label="Brand Name"
                                placeholder="Enter brand name"
                                value={brand.name}
                                onChange={(e) => setBrand({ ...brand, name: e.target.value })}
                            />
                            <Space h="md" />
                            <TextInput
                                label="Brand Logo"
                                placeholder="Enter brand logo"
                                value={brand.logo}
                                onChange={(e) => setBrand({ ...brand, logo: e.target.value })}
                            />
                            <Space h="xl" />
                            <Button type="submit"
                                onClick={onSave}
                            >Save</Button>
                        </form>
                    </Container>
                )}
            </div>
        </Modal>
    );
}