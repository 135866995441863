import React, { useState, useEffect, useContext } from 'react';
import { collection, getCountFromServer, query, where } from 'firebase/firestore';
import { auth, db } from './../../firebase-config';
import { onAuthStateChanged } from 'firebase/auth';
import { Helmet } from 'react-helmet';
import { Loader, Title, Text, Container, Button, Center } from '@mantine/core';
import styles from './detailDashboard.module.css';
import { StatsGrid } from '../../components';
import { AppContext } from '../../App';

export const DetailDashboard = () => {
    const { globalState } = useContext(AppContext);

    const [allBrands, setAllBrands] = useState(globalState.brands);

    const [counts, setCounts] = useState({});

    const [todayCounts, setTodayCounts] = useState([{
        stamps: 0,
        rewards: 0
    }]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (!user) {
                window.location.href = '/admin';
            }
        });

        // Clean up the subscription
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        setAllBrands(globalState.brands);
    }, [globalState.brands]);

    useEffect(() => {
        let fetchData = async (brandId) => {
            try {
                const countsData = {};
                const collections = ['brands', 'stamps', 'users', 'rewards'];

                const fetchCollectionCount = async (coll, fieldName, value) => {
                    const q = query(collection(db, coll), where(fieldName, '==', value));
                    const querySnapshot = await getCountFromServer(q);
                    return querySnapshot.data().count;
                };

                const fetchBrandUserCount = async (coll) => {
                    const q = query(collection(db, coll, brandId, 'clients'));
                    const querySnapshot = await getCountFromServer(q);
                    return querySnapshot.data().count;
                };

                await Promise.all(collections.map(async coll => {
                    let count;
                    if (coll === 'users') {
                        count = await fetchBrandUserCount('brands');
                    }
                    else {
                        count = await fetchCollectionCount(coll, 'brandId', brandId);
                    }
                    countsData[coll] = count;
                }));
                
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
    }, []);

    useEffect(() => {
        setLoading(true);
        let todayCountsLocal = {};
        let yesterdayCounts = {};

        const fetchTodayData = async (brandId) => {
            try {
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                const tomorrow = new Date(today);
                tomorrow.setDate(today.getDate() + 1);

                const fetchCollectionCount = async (coll, fieldName) => {
                    // const q = query(collection(db, coll), where(fieldName, '>=', today), where(fieldName, '<', tomorrow));
                    var q;
                    if (brandId !== undefined) {
                        q = query(collection(db, coll), where(fieldName, '>=', today), where(fieldName, '<', tomorrow), where('brandId', '==', globalState.currentBrand));
                    }
                    const querySnapshot = await getCountFromServer(q);
                    return querySnapshot.data().count;
                };

                const [stampsCount, rewardsCount] = await Promise.all([
                    fetchCollectionCount('stamps', 'stampedDate'),
                    fetchCollectionCount('rewards', 'claimedDate')
                ]);

                todayCountsLocal = {
                    stamps: stampsCount,
                    rewards: rewardsCount
                }

                setLoading(false);
                await fetchYesterdayData(brandId);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        const fetchYesterdayData = async (brandId) => {
            setLoading(true);
            try {
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                const yesterday = new Date(today);
                yesterday.setDate(today.getDate() - 1);

                const fetchCollectionCount = async (coll, fieldName) => {
                    // const q = query(collection(db, coll), where(fieldName, '>=', yesterday), where(fieldName, '<', today));
                    var q;
                    if (brandId !== undefined) {
                        q = query(collection(db, coll), where(fieldName, '>=', yesterday), where(fieldName, '<', today), where('brandId', '==', brandId));
                    }
                    const querySnapshot = await getCountFromServer(q);
                    return querySnapshot.data().count;
                };

                const [stampsCount, rewardsCount] = await Promise.all([
                    fetchCollectionCount('stamps', 'stampedDate'),
                    fetchCollectionCount('rewards', 'claimedDate')
                ]);

                yesterdayCounts = {
                    stamps: stampsCount,
                    rewards: rewardsCount
                };

                calculatePercentageDifference();
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        const calculatePercentageDifference = () => {
            const stampsDiff = yesterdayCounts.stamps !== 0 ? ((todayCountsLocal.stamps - yesterdayCounts.stamps) / yesterdayCounts.stamps) * 100 : 0;
            const rewardsDiff = yesterdayCounts.rewards !== 0 ? ((todayCountsLocal.rewards - yesterdayCounts.rewards) / yesterdayCounts.rewards) * 100 : 0;

            setTodayCounts([...{
                stamps: todayCountsLocal.stamps,
                rewards: todayCountsLocal.rewards,
                stampsDiff: stampsDiff,
                rewardsDiff: rewardsDiff
            }]);
            console.log('todayCountsLocal', todayCountsLocal);
        };

        // fetchTodayData();
        allBrands.forEach(brand => {
            fetchTodayData(brand.id);
        });
    }, [globalState.currentBrand, allBrands]);

    return (
        <>
            <Helmet>
                <title>Lollet Admin - Dashboard</title>
            </Helmet>
            <Container
                size="xxl"
                className={styles.container}
            >
                {error && <Text color="red" align="center">{error}</Text>}
                {loading ? (
                    <Loader />
                ) : (
                    <>
                        {/* {allBrands.length > 0 && counts.length > 0 && todayCounts.length > 0 && (
                            allBrands.map((brand, index) => (
                                // <StatsGrid data={[{
                                //     title: 'Brand', value: globalState.brands.find(
                                //         brand => brand.id === globalState.currentBrand)?.name, smallText: true, diff: 0, icon: 'brands'
                                // },
                                // { title: 'Users', value: counts.users, diff: 0, redirect: '/admin/users', icon: 'users' },
                                // { title: 'Stamps', value: counts.stamps, today: todayCounts.stamps, diff: todayCounts.stampsDiff, icon: 'stamps' },
                                // { title: 'Rewards', value: counts.rewards, today: todayCounts.rewards, diff: todayCounts.rewardsDiff, icon: 'rewards' }
                                // ]} />
                                <StatsGrid data={[
                                    { title: 'Brand', value: brand.name, smallText: true, diff: 0, icon: 'brands' },
                                    { title: 'Users', value: counts[index].users, diff: 0, redirect: '/admin/users', icon: 'users' },
                                    { title: 'Stamps', value: counts[index].stamps, today: todayCounts[index].stamps, diff: todayCounts[index].stampsDiff, icon: 'stamps' },
                                    { title: 'Rewards', value: counts[index].rewards, today: todayCounts[index].rewards, diff: todayCounts[index].rewardsDiff, icon: 'rewards' }
                                ]} />
                            ))
                        )} */}
                    </>
                )}
            </Container>
        </>
    );
};
