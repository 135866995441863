import React, { useEffect } from 'react';
import styles from './appDownload.module.css';

const getDeviceInfo = () => {
    const userAgent = navigator.userAgent;
    const vendor = navigator.vendor;
    const isTouchable = 'ontouchend' in document;
    const isIPad = /\b(\w*Macintosh\w*)\b/.test(userAgent) && isTouchable;
    const isIPhone = /\b(\w*iPhone\w*)\b/.test(userAgent) && /\b(\w*Mobile\w*)\b/.test(userAgent) && isTouchable;
    const isMobile = isIPad || isIPhone || /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
    const isMacOS = !isMobile && !(isIPad || isIPhone) && /Mac OS/i.test(userAgent);

    const isIOS = isMobile && (isIPad || isIPhone) && /\b(\w*Apple\w*)\b/.test(vendor) && !/\b(\w*CriOS\w*)\b/.test(userAgent) && !/\b(\w*FxiOS\w*)\b/.test(userAgent);
    const isIOS_noVendor = isMobile && (isIPad || isIPhone);

    return {
        isTouchable,
        isIPad,
        isIPhone,
        isMobile,
        isMacOS,
        isIOS,
        isIOS_noVendor,
        vendor,
    };
};

export const AppDownload = () => {
    useEffect(() => {
        const deviceInfo = getDeviceInfo();
        if (deviceInfo.isMobile) {
            if (deviceInfo.isIPhone || deviceInfo.isIPad || deviceInfo.isIOS || deviceInfo.isIOS_noVendor) {
                // Redirect to app store
                window.location.href = 'https://apps.apple.com/us/app/lollet/id6482976966';
            } else {
                // Redirect to google play
                window.location.href = 'https://play.google.com/store/apps/details?id=app.lollet';
            }
        }
    }, []);

    return (
        <div className={styles.container}>
            <img src="assets/logo.png" alt="App Logo" className={styles.logo} />
            <div className={styles['download-buttons']}>
                <a href="https://play.google.com/store/apps/details?id=app.lollet">
                    <img src="assets/play-store.png" alt="Download on Play Store" />
                </a>
                <a href="https://apps.apple.com/us/app/lollet/id6482976966">
                    <img src="assets/app-store.png" alt="Download on App Store" />
                </a>
            </div>
        </div>
    );
}
