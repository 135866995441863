import { useEffect, useState } from 'react';
import { auth, functions, httpsCallable, signInWithCustomToken } from '../../firebase-config';
import { onAuthStateChanged } from 'firebase/auth';
import { Helmet } from 'react-helmet';
import { Button, Input, Title, Image, PinInput } from '@mantine/core';
import { IMaskInput } from 'react-imask';
import styles from './login.module.css';
import { notifications } from '@mantine/notifications';

export const Login = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [otpVerified, setOtpVerified] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                window.location.href = '/admin/dashboard';
            }
        });

        // Clean up the subscription
        return () => unsubscribe();
    }, []);

    const requestOtp = () => {
        const sendOtp = httpsCallable(functions, 'sendOTP');
        setLoading(true);
        sendOtp({ phone: phoneNumber })
            .then((result) => {
                if (result.data.status === 200) {
                    setOtpSent(true);
                    notifications.show({
                        color: 'green',
                        title: 'OTP sent successfully',
                        autoClose: 5000
                    })
                } else {
                    notifications.show({
                        color: 'red',
                        title: 'Error sending OTP',
                        autoClose: 5000
                    })
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error sending OTP", error);
                notifications.show({
                    color: 'red',
                    title: 'Error sending OTP',
                    autoClose: 5000
                })
                setLoading(false);
            });
    };

    const verifyOtp = () => {
        const verifyOtp = httpsCallable(functions, 'verifyOTP');
        setLoading(true);
        console.log(phoneNumber, otp);
        verifyOtp({
            phone: phoneNumber,
            code: otp
        })
            .then(async (result) => {
                if (result.data.status === 200) {
                    setOtpVerified(true);
                    notifications.show({
                        color: 'green',
                        title: 'OTP verified successfully',
                        message: 'Logging you in...',
                        autoClose: 5000
                    })
                    let token = result.data.extra.token;
                    await signInWithCustomToken(auth, token);
                } else {
                    notifications.show({
                        color: 'red',
                        title: 'Invalid OTP',
                        autoClose: 5000
                    })
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error verifying OTP", error);
                notifications.show({
                    color: 'red',
                    title: 'Error verifying OTP',
                    autoClose: 5000
                })
                setLoading(false);
            });
    };

    return (
        <>
            <Helmet>
                <title>Lollet Admin - Login</title>
            </Helmet>
            <div className={styles.container}>
                <Image src="/assets/logo_biz.png" alt="Lollet Logo" h={150} w={150} />
                <Title order={2}>Lollet Admin</Title>
                {!otpSent && !otpVerified && (
                    <div className={styles.inputContainer}>
                        <Input
                            component={IMaskInput}
                            mask="+977 0000000000"
                            placeholder="Enter phone number"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                        <Button variant="filled" onClick={requestOtp} loading={loading}>Request OTP</Button>
                    </div>
                )}
                {otpSent && !otpVerified && (
                    <div>
                        <PinInput disabled={loading} type="number" placeholder="_" oneTimeCode length={6} value={otp} onChange={(value) => {
                            console.log(value);
                            setOtp(value)
                        }} onComplete={verifyOtp} />
                    </div>
                )}
            </div>
        </>
    );
};
